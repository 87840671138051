import { Center, SimpleGrid } from "@mantine/core";
import LunchCard from "../../components/LunchCard";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";

export default function Home() {
  const { data, error, isLoading } = useSWR(
    "https://lunchflare01.tumai.workers.dev/lunches",
    fetcher
  );

  if (error || isLoading) return null;

  let lunches;

  try {
    lunches = JSON.parse(data);
  } catch (e) {
    console.log(e);
  }

  if (!lunches) return null;

  return (
    <Center mx="auto">
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: "62rem", cols: 3, spacing: "md" },
          { maxWidth: "48rem", cols: 2, spacing: "sm" },
          { maxWidth: "36rem", cols: 1, spacing: "sm" },
        ]}
      >
        {lunches.map((lunch: any) => {
          return <LunchCard key={lunch.id} {...lunch} />;
        })}
      </SimpleGrid>
    </Center>
  );
}
