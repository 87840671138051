import { forwardRef } from "react";
import {
  Header,
  Image,
  Flex,
  Avatar,
  Select,
  Text,
  Group,
} from "@mantine/core";
import { useUserContext } from "../../context/UserContext";
import lunchflareLogo from "../../assets/lunchflare.png";
import users from "../../mocks/users";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  value: string;
}

const CustomSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value: userId }, ref) => {
    const userData = users.find((user) => user.id === userId);

    const { login } = useUserContext();

    return (
      <div ref={ref} style={{ paddingBottom: "10px" }}>
        <Group
          noWrap
          mb={10}
          onClick={() => userData?.id && login(userData?.id)}
        >
          <Avatar
            src={userData?.src}
            radius="xl"
            size="lg"
            mr="1rem"
            sx={{ cursor: "pointer" }}
          />
          <div>
            <Text size="sm">{userData?.name}</Text>
          </div>
        </Group>
      </div>
    );
  }
);

export default function TopHeader() {
  const { userData } = useUserContext();

  return (
    <Header height={100} p="xs">
      <Flex
        maw={"90%"}
        ml="auto"
        gap="sm"
        justify="center"
        align="center"
        direction="row"
      >
        <Image
          height={80}
          fit="contain"
          src={lunchflareLogo}
          alt="lunchflare logo"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            src={userData?.src}
            radius="xl"
            size="lg"
            mr="1rem"
            sx={{ cursor: "pointer" }}
          />
          <Select
            itemComponent={CustomSelectItem}
            data={users.map((user) => ({ value: user.id, label: user.name }))}
            maxDropdownHeight={400}
            value={users.find((user) => user.id === userData.userId)?.id}
          />
        </div>
      </Flex>
    </Header>
  );
}
