import React, { useState, useEffect } from "react";
import {
  Modal,
  Title,
  Card,
  Group,
  Text,
  MultiSelect,
  Loader,
} from "@mantine/core";

import { useRestaurantApi } from "../hooks/useRestaurantApi";

import foodOptionsMock from "../mocks/foodoptions";
import { OFFICE_CODES } from "../utils/constants";

const FormModal = ({
  opened,
  onClose,
  participants,
  radius,
  addRestaurant,
  office,
}: {
  opened: boolean;
  onClose: () => void;
  participants: string[];
  radius?: number;
  addRestaurant: (id: string, name: string) => void;
  office: OFFICE_CODES;
}) => {
  const getFoodOptionsFilters = () => {
    const options: string[] = [];
    participants.map((userId) => {
      const userOptions = foodOptionsMock.find(
        (user) => user.id === userId
      )?.options;

      if (userOptions?.length) {
        userOptions.map((userOption) => {
          if (!options.find((opt) => opt === userOption)) {
            !!userOption && options.push(userOption);
          }
        });
      }
      return userId;
    });
    console.log("options", options);
    return options;
  };

  const [restrictions, setRestrictions] = useState<string[]>();

  useEffect(() => {
    setRestrictions(getFoodOptionsFilters());
  }, [participants]);

  const { data, isLoading } = useRestaurantApi(restrictions, radius, office);

  return (
    <Modal opened={opened} onClose={onClose} size="70%">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          flex: 1,
          minHeight: "300px",
        }}
      >
        <Group>
          <MultiSelect
            my={20}
            data={[
              { id: "1", value: "vegetarian", label: "vegetarian" },
              { id: "2", value: "halal", label: "halal" },
              { id: "3", value: "vegan", label: "vegan" },
              { id: "4", value: "kosher", label: "kosher" },
            ]}
            label="Current restrictions according selected colleagues"
            onChange={(val) => setRestrictions(val)}
            value={restrictions}
          />
        </Group>

        <Group>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Title order={4} my={20}>
                Suggested Restaurants:
              </Title>
              <Group>
                {data?.features?.map(
                  (feat: {
                    properties: {
                      name: string;
                      place_id: string;
                      address_line2: string;
                    };
                  }) => (
                    <Card
                      shadow="sm"
                      padding="lg"
                      radius="md"
                      withBorder
                      maw={300}
                      onClick={() =>
                        addRestaurant(
                          feat.properties.place_id,
                          feat.properties.name
                        )
                      }
                    >
                      <Text weight={500}>{feat.properties.name}</Text>
                      <Text weight={500}>{feat.properties.address_line2}</Text>
                    </Card>
                  )
                )}
              </Group>
            </>
          )}
        </Group>
      </div>
    </Modal>
  );
};

export default FormModal;
