import React from "react";

import { Group, Text, Image, Checkbox, Title } from "@mantine/core";

import { useUserContext } from "../../context/UserContext";

import { FoodOptions } from "../../types/types";

import foodOptionsMock from "../../mocks/foodoptions";

const foodOptions = [
  FoodOptions.Halal,
  FoodOptions.Kosher,
  FoodOptions.Organic,
  FoodOptions.Vegan,
  FoodOptions.Vegetarian,
];

export default function Profile() {
  const { userData } = useUserContext();

  const optionsSelectedForUser = foodOptionsMock?.find(
    (opt) => opt.id === userData.userId
  );

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Image
        maw={240}
        mx="auto"
        radius="md"
        src={userData.src}
        alt="Random image"
        mb={40}
      />
      <Group mb={40}>
        <Text>{userData.name}</Text>
      </Group>
      <Group>
        <Title order={4}>I like....</Title>
        {foodOptions.map((opt) => (
          <Checkbox
            label={opt}
            checked={optionsSelectedForUser?.options.includes(opt)}
          />
        ))}
      </Group>
    </div>
  );
}
