export enum OFFICE_CODES {
  LIS = "LIS",
  LHR = "LHR",
  ATT = "ATT",
}

export const OFFICE_NAMES = {
  [OFFICE_CODES.LIS]: "Lisbon",
  [OFFICE_CODES.LHR]: "London",
  [OFFICE_CODES.ATT]: "Austin",
};

export const OFFICES_LOCATIONS = {
  [OFFICE_CODES.LIS]: {
    lat: 38.72672828162483,
    long: -9.144228451464379,
  },
  [OFFICE_CODES.LHR]: {
    lat: 51.502123565025876,
    long: -0.11849815019045018,
  },
  [OFFICE_CODES.ATT]: {
    lat: 30.261593060906748,
    long: -97.72662859280376,
  },
};
