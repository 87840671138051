import { Button } from "@mantine/core";

export default function NavButton({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <Button
      color="orange"
      radius="md"
      size="md"
      sx={{ width: "130px" }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
