import { useState } from "react";
import {
  Card,
  Text,
  Badge,
  Button,
  Tooltip,
  Avatar,
  Flex,
} from "@mantine/core";
import { Lunch } from "../types/types";
import dayjs from "dayjs";
import { useUserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { OFFICE_NAMES } from "../utils/constants";
import users from "../mocks/users";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

const LunchCard: React.FC<Lunch> = ({
  id,
  participants,
  maxParticipants,
  description,
  officeCode,
  eventDate,
  public: isPublic,
  place,
  creatorId,
}) => {
  const { userData } = useUserContext();
  const [runConfetti, setRunConfetti] = useState(false);
  const navigate = useNavigate();

  const { width, height } = useWindowSize();

  console.log({ creatorId });

  const lunchCreator = users.find((user) => user.id == creatorId);

  const registerToLunch = async () => {
    const isUserAlreadyRegistered = participants.find(
      (p) => p.id === userData.userId
    );

    if (!!isUserAlreadyRegistered)
      return alert("You are already registered to this lunch");

    try {
      const data = JSON.stringify({
        lunchId: id,
        newUser: {
          id: userData.userId,
          name: userData.name,
          src: userData.src,
        },
      });
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data,
        url: "https://lunchflare01.tumai.workers.dev/register",
      };
      await axios(options);
      setRunConfetti(true);
      alert("You have successfully registered for this lunch!");
      setTimeout(() => {
        setRunConfetti(false);
      }, 5000);
      navigate("/");
    } catch (e) {
      console.log("error!", e);
      alert("Oh noes! Something went wront while registering for this lunch!");
      navigate("/");
    }
  };

  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Flex gap="md" justify="space-between" align="center" direction="row">
          <Text weight={500}>Lunch {`@ ${place?.name ?? null}`}</Text>
          {/* @ts-ignore */}
          <Tooltip label={OFFICE_NAMES[officeCode]}>
            <Badge color="orange" variant="light" sx={{ cursor: "pointer" }}>
              {officeCode}
            </Badge>
          </Tooltip>
        </Flex>

        <Text size="xs" color="dimmed">
          Organizer: {lunchCreator && lunchCreator.name}
        </Text>

        <Text size="xs" color="dimmed">
          {dayjs(eventDate).format("DD/MM/YYYY - HH:mm")}
        </Text>

        <Text size="sm" color="dimmed" pt={"1rem"}>
          {description}
        </Text>

        <Flex
          mih={50}
          pt="0.75rem"
          gap="sm"
          justify="center"
          align="center"
          direction="row"
          wrap="wrap"
        >
          {participants.map((participant) => {
            return (
              <Tooltip label={participant.name} key={participant.id}>
                <Avatar
                  alt={participant.name}
                  src={participant.src}
                  radius="xl"
                />
              </Tooltip>
            );
          })}
        </Flex>

        <Button
          variant="light"
          color="orange"
          fullWidth
          mt="md"
          radius="md"
          disabled={participants.length >= maxParticipants || !isPublic}
          onClick={registerToLunch}
        >
          Register
        </Button>
      </Card>
      <Confetti
        width={width}
        height={height}
        recycle={false}
        run={runConfetti}
      />
    </>
  );
};

export default LunchCard;
