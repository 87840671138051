import { MantineProvider, AppShell, Navbar as NavWrapper } from "@mantine/core";

import NavBar from "./pages/NavBar/NavBar";
import { Outlet } from "react-router-dom";
import { useInitApp } from "./hooks/useInitApp";
import Header from "./pages/Header/Header";

export default function App() {
  useInitApp();

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <AppShell
        padding="md"
        navbar={
          <NavWrapper width={{ base: 150 }} height={1000} p="xs">
            <NavBar />
          </NavWrapper>
        }
        header={<Header />}
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        <Outlet />
      </AppShell>
    </MantineProvider>
  );
}
