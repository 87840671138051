const foodOptionsMock = [
  { id: "1", options: ["vegetarian", "vegan"] },
  { id: "2", options: [""] },
  { id: "3", options: ["halal"] },
  { id: "4", options: ["kosher"] },
  { id: "5", options: [""] },
  { id: "6", options: ["vegetarian"] },
  { id: "7", options: ["vegan"] },
  { id: "8", options: [""] },
  { id: "9", options: [""] },
];

export default foodOptionsMock;
