import React, { createContext, useContext, useState } from "react";
import users from "../mocks/users";

import { OFFICE_CODES, OFFICES_LOCATIONS } from "../utils/constants";

const DEFAULT_LOCATION_LONDON = {
  lat: OFFICES_LOCATIONS[OFFICE_CODES.LHR].lat,
  long: OFFICES_LOCATIONS[OFFICE_CODES.LHR].long,
};

const DEFAULT_USER_ID = "1";
const DEFAULT_USER = users.find((u) => u.id === DEFAULT_USER_ID);

const userInitialData = {
  userData: {
    userId: DEFAULT_USER_ID,
    name: "",
    src: "",
    location: {
      lat: DEFAULT_LOCATION_LONDON.lat,
      long: DEFAULT_LOCATION_LONDON.long,
    },
  },
  login: (_id: string) => {},
  logout: () => {},
  updateUserLocation: (_loc: { lat: number; long: number }) => {},
};

const UserContext = createContext(userInitialData);

export const useUserContext = () => {
  const data = useContext(UserContext);

  return data;
};

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState({
    userId: DEFAULT_USER_ID,
    name: DEFAULT_USER?.name ?? "",
    src: DEFAULT_USER?.src ?? "",
    location: { lat: 0, long: 0 },
  });

  const updateUserLocation = (location: { lat: number; long: number }) => {
    setUserData({ ...userData, location });
  };

  const login = (userId: string) => {
    const newUserData = users.find((user) => user.id === userId);
    setUserData({
      userId,
      name: newUserData?.name || "",
      src: newUserData?.src || "",
      location: userData.location,
    });
  };

  const logout = () => {
    setUserData({ ...userData, userId: "" });
  };

  return (
    <UserContext.Provider
      value={{ userData, updateUserLocation, login, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
