import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import {
  MultiSelect,
  Button,
  Box,
  Radio,
  Group,
  NumberInput,
  Textarea,
  Title,
} from "@mantine/core";

import { OFFICE_CODES } from "../../utils/constants";
import { Lunch, Participant } from "../../types/types";
import users from "../../mocks/users";
import { useUserContext } from "../../context/UserContext";
import FormModal from "../../components/FormModal";

interface CreateEventForm {
  participants: string[];
  maxParticipants: number;
  eventDate: Date;
  place: { id: string; name: string };
  officeCode: OFFICE_CODES;
  public: string;
  description: string;
  radius?: number;
}

const CreateEventPage = () => {
  const [opened, setOpen] = useState(false);

  const { userData } = useUserContext();

  const navigate = useNavigate();

  const form = useForm<CreateEventForm>({
    initialValues: {
      participants: [],
      maxParticipants: 10,
      eventDate: new Date(),
      place: { id: "", name: "" },
      officeCode: OFFICE_CODES.LHR, //check if there is an office close to the latitude/longitude of the user
      public: "true",
      description: "",
      radius: 10000,
    },
  });

  const handleSubmit = async (values: CreateEventForm) => {
    const participants: Participant[] = values.participants.map(
      (userId): Participant => {
        const user: Participant | undefined = users.find(
          (us) => us.id === userId
        );
        return user || { id: "", name: "", src: "" };
      }
    );

    const payloadValues: CreateEventForm = { ...values };

    delete payloadValues.radius;

    const payload: Lunch = {
      ...payloadValues,
      public: values.public === "true",
      creatorId: userData.userId,
      participants,
    };

    try {
      const data = JSON.stringify(payload);
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data,
        url: "https://lunchflare01.tumai.workers.dev/create",
      };
      axios(options);
      navigate("/");
    } catch (e) {
      console.log("error!", e);
    }
  };

  return (
    <Box>
      <Title order={3} mb={40}>
        Let's create your event
      </Title>
      <form
        onSubmit={form.onSubmit((values) => {
          handleSubmit(values);
        })}
      >
        <Textarea
          placeholder="Your description"
          label="Any description for this event?"
          withAsterisk
          {...form.getInputProps("description")}
        />

        <Radio.Group
          name="public"
          label="Type of event"
          withAsterisk
          mt="xs"
          {...form.getInputProps("public")}
        >
          <Radio value="true" label="Public" />
          <Radio value="false" label="Private" />
        </Radio.Group>
        {form.values.public === "true" && (
          <Group mt="xs" my={20}>
            <NumberInput
              name="maxParticipants"
              label="Maximum number of participants"
              placeholder="Nr participants"
              width={100}
              min={1}
              {...form.getInputProps("maxParticipants")}
            />
          </Group>
        )}

        <Radio.Group
          my={20}
          name="officeCode"
          label="Office"
          description="Which is your closes office?"
          withAsterisk
          {...form.getInputProps("officeCode")}
        >
          <Group mt="xs">
            <Radio value={OFFICE_CODES.LIS} label="Lisbon" />
            <Radio value={OFFICE_CODES.LHR} label="London" />
            <Radio value={OFFICE_CODES.ATT} label="Austin" />
          </Group>
        </Radio.Group>

        <Group mt="xs" my={20}>
          <NumberInput
            label="Radius from the office (m)"
            placeholder="Radius"
            width={1000}
            min={1}
            {...form.getInputProps("radius")}
          />
        </Group>

        <MultiSelect
          my={20}
          data={users.map((user) => ({ value: user.id, label: user.name }))}
          label="Select the participants"
          placeholder="Pick all that you like"
          searchable
          nothingFound="Nothing found"
          max={form.values.maxParticipants}
          {...form.getInputProps("participants")}
        />

        <DateInput
          label="Pick a date"
          placeholder="Select a Date for the event"
          maw={400}
          mb={20}
          {...form.getInputProps("eventDate")}
        />

        <Group>
          <div>
            <Title order={5}>
              {form.values.place.name
                ? `You selected the restaurant: ${form.values.place.name}`
                : "You don't have a restaurant selected :("}
            </Title>

            <Button
              onClick={() => setOpen(true)}
              mt="md"
              disabled={!form.values.participants.length}
            >
              {!form.values.place.name
                ? "Select a restaurant"
                : "Select another restaurant"}
            </Button>
          </div>
        </Group>

        <Group my={50}>
          <Button
            type="submit"
            mt="md"
            disabled={!form.values.participants.length}
          >
            Submit
          </Button>
        </Group>
      </form>

      <FormModal
        office={form.values.officeCode}
        opened={opened}
        onClose={() => setOpen(false)}
        participants={form.values.participants}
        addRestaurant={(id, name) => {
          form.setValues({
            place: { id, name },
          });
          setOpen(false);
        }}
      />
    </Box>
  );
};

export default CreateEventPage;
