import { Flex } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import NavButton from "../../components/NavButton";

export default function NavBar() {
  let navigate = useNavigate();

  return (
    <Flex
      mih={150}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
      pt="0.5rem"
    >
      <NavButton onClick={() => navigate("")}>Lunches</NavButton>
      <NavButton onClick={() => navigate("/profile")}>Profile</NavButton>
      <NavButton onClick={() => navigate("/create")}>Create</NavButton>
    </Flex>
  );
}
