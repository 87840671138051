import useSWR from "swr";

import { fetcher } from "../utils/fetcher";
import { useUserContext } from "../context/UserContext";
import { OFFICE_CODES, OFFICES_LOCATIONS } from "../utils/constants";

const API_KEY = "2da05a3e68c649dd94dd764a47b912d0"; //Should be in ENV file

const ENDPOINT = `https://api.geoapify.com/v2/places?categories=catering.restaurant,catering.food_court,catering.fast_food,commercial.food_and_drink&apiKey=${API_KEY}`;

export const useRestaurantApi = (
  filters?: string[],
  radius: number = 10000,
  office?: OFFICE_CODES
) => {
  const { userData } = useUserContext();
  const conditions = filters?.length ? `&conditions=${filters.join(",")}` : "";
  const selectedOffice = office && OFFICES_LOCATIONS[office];

  const long = selectedOffice?.long || userData.location.long;
  const lat = selectedOffice?.lat || userData.location.lat;

  return useSWR(
    `${ENDPOINT}${conditions}&proximity=${long},${lat}&filter=circle:${long},${lat},${radius}&limit=50`,
    fetcher
  );
};
