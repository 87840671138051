export type Participant = {
  id: string;
  name: string;
  src: string;
};

type Place = {
  id: string;
  name: string;
};

export type Lunch = {
  id?: string;
  creatorId: string;
  participants: Participant[];
  maxParticipants: number;
  description: string;
  eventDate: Date;
  place: Place;
  officeCode: string;
  public: boolean;
};

export enum FoodOptions {
  Vegetarian = "vegetarian",
  Kosher = "kosher",
  Halal = "halal",
  Vegan = "vegan",
  Organic = "organic",
}
