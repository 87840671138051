import { useEffect } from "react";
import { useUserContext } from "../context/UserContext";

export const useInitApp = () => {
  const { updateUserLocation } = useUserContext();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const { latitude, longitude } = position.coords;
        if (typeof latitude !== "undefined" && typeof longitude !== "undefined")
          updateUserLocation({
            lat: latitude,
            long: longitude,
          });
      });
    } else {
      //TODO - Ask user to enable location or allow to select it manually on the form?
    }
  }, []);
};
